import {
  getApiKeysRequest,
  createApiKeyRequest,
  deleteApiKeyRequest,
  updateApiKeyRequest
} from '@/api/api-keys';

import { showSuccessToast, showErrorToast } from '@/utils/toasts';

export const state = {
  list: [],
  totalItems: 0
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
}

export const actions = {
  async getApiKeys({ commit }, params) {
    try {
      const { code, data } = await getApiKeysRequest(params);
      if (code === 200 && data?.length) {
        commit('SET_LIST', data);
        commit('SET_TOTAL_RECORDS', data.total || 0);
      }
    } catch (e) {
      console.error(e);
    }
  },
  async createApiKey(_, payload) {
    try {
      const response = await createApiKeyRequest(payload);
      if (response?.code === 200 && response?.data) {
        showSuccessToast('Key created successfully');
      } else {
        showErrorToast(response?.data ?? 'Something went wrong');
      }
    } catch (e) {
      console.error(e);
    }
  },
  async updateApiKey(_, { id, data }) {
    try {
      const response = await updateApiKeyRequest(id, data);
      if (response.code === 200) {
        showSuccessToast('Key updated successfully');
      } else {
        showErrorToast(response.data);
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  },
  async deleteApiKey(_, payload) {
    try {
      const { code, data } = await deleteApiKeyRequest(payload);
      if (code === 200) {
        showSuccessToast('Key deleted successfully');
      } else {
        showErrorToast(data ?? 'Something went wrong');
      }
    } catch (e) {
      console.error(e);
    }
  },
};

export const getters = {
  emailsOptions(state) {
    return [
      { value: null, text: 'Choose Email' },
      ...state.list
        .map(({ id, title }) => ({ value: id, text: title }))
    ];
  },
}
